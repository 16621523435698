import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NotificationsModule } from '@app/notifications/notifications.module';
import { ButtonFabModule } from '@core/components/button-fab/button-fab.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { NearestMeetBtnModule } from '@core/components/nearest-meet-btn/nearest-meet-btn.module';
import { HeaderComponent } from '@core/header/header.component';
import { OnUiSpriteModule } from 'onclass-ui';
import { ConstructorLayoutComponent } from './constructor-layout.component';

@NgModule({
  declarations: [ConstructorLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NotificationsModule,
    OnUiSpriteModule,
    ButtonFabModule,
    NearestMeetBtnModule,
    HeaderComponent,
    MobileModalModule,
    ModalModule,
  ],
  exports: [ConstructorLayoutComponent],
})
export class ConstructorLayoutModule {}
