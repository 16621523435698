<div *ngIf="isShowLanding" class="app app-landing" [ngClass]="pageStyle">
  <ng-container #app_header></ng-container>
  <div class="container">
    <router-outlet #routerOutlet="outlet"></router-outlet>
  </div>
  <app-footer [pageStyle]="pageStyle"></app-footer>
</div>

<div *ngIf="debug$ | async" class="grid">
  <div class="w1200"><b><i>1200px</i></b></div>
  <div class="w1024"><b><i>1024px</i></b></div>
  <div class="w768"><b><i>768px</i></b></div>
  <div class="w320"><b><i>320px</i></b></div>
</div>
