<div class="app app-main" [ngClass]="pageStyle"
     [class.mobile-app]="deviceService.isMobile()">
  <ng-container #app_header></ng-container>
  <app-footer *ngIf="isFooterVisible"></app-footer>

  <main class="app-body">
    <div class="spacer"></div>
    <app-profile-left-sidebar></app-profile-left-sidebar>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
    <app-profile-right-sidebar *ngIf="deviceService.isDesktop()"></app-profile-right-sidebar>
    <div class="spacer"></div>
  </main>

  <ng-template [ngIf]="isAvailableChat && isChatVisible">
    <div *ngIf="deviceService.isDesktop()" class="chat-action" [class.disabled]="!iframe">
      <app-button-fab
        [icon]="'chat-bubble'"
        [counter]="unreadCount"
        [isToggled]="false"
        (click)="openChatWindow()">
      </app-button-fab>
    </div>
    <div class="chat-wrap"
         [class.display]="isChatVisible && alternativeChatState"
         (mouseenter)="onMouseenter()"
         (mouseleave)="onMouseleave()">
         <iframe [src]="safeChatUrl" id="chatFrame"
         width="100%" sandbox="allow-same-origin allow-scripts allow-forms"
         allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
         allowfullscreen
         class="chat">
     </iframe>
    </div>
  </ng-template>

  <ng-container *ngIf="fileFromChat">
    <app-modal *ngIf="!deviceService.isMobile()" [thin]="true" [canClose]="true" (closed)="closeFileFromChat()">
      <app-modal-body>
        <img [src]="fileFromChat" class="chat-image" />
      </app-modal-body>
    </app-modal>

    <app-mobile-modal *ngIf="deviceService.isMobile()" (closed)="closeFileFromChat()">
      <app-mobile-modal-body>
        <img [src]="fileFromChat" class="chat-image" />
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-container>
</div>
