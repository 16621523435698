import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CleanLayoutComponent } from './clean-layout.component';

@NgModule({
  declarations: [CleanLayoutComponent],
  imports: [CommonModule, RouterModule],
  exports: [CleanLayoutComponent],
})
export class CleanLayoutModule {}
