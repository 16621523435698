import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DestroyableComponent } from '@models/destroyable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './clean-layout.component.html',
  styleUrls: ['./clean-layout.component.scss'],
})
export class CleanLayoutComponent extends DestroyableComponent implements OnInit {
  public pageStyle = '';

  constructor(public errorNotificationService: ErrorNotificationService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (url) => {
        // logger.log('CleanLayoutComponent -> route.url -> snapshot=', this.route.snapshot);
        const data = this.route.snapshot.data;
        this.pageStyle = data.page_style || '';
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });
  }
}
