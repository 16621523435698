import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FeaturesService } from '@core/services';
import { EContentPanelRoute } from 'lingo2-models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() pageStyle: string;

  public routes = EContentPanelRoute;

  public get copyYear(): number {
    return new Date().getFullYear();
  }

  public get isLimitedVersion() {
    return FeaturesService.isLimitedVersion;
  }
}
