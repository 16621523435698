import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from '@core/layouts/main/sidebar/sidebar.service';
import {
  AccountService,
  ContextService,
  FeaturesService,
  PlatformService,
  ProfileService,
  ScreenService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import * as DataActions from '@store/actions/data.actions';
import { loadAccountCheck } from '@store/actions/data.actions';
import { getAccountCheck } from '@store/reducers/data.reducer';
import { getMe, getMyProfile } from '@store/reducers/profile.reducer';
import {
  FeatureEnum,
  IAccountCheck,
  User,
  UserAchievementEnum,
  UserProfile,
  UserProfileVerificationEnum,
  UserSegmentEnum,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil, switchMap } from 'rxjs';
import { first, tap } from 'rxjs/operators';

export enum ProfileStepsEnum {
  personal = 'personal',
  teaching = 'teaching',
  services = 'services',
  schedule = 'schedule',
  payinfo = 'payinfo',
  card = 'card', // СТарый биллинг с банк картами
  payout = 'payout', // Новый биллинг
  payment = 'payment', // Новый биллинг
  application = 'application',
  main = 'main',
  about = 'about',
  contacts = 'contacts',
  education = 'education',
  experience = 'experience',
}

interface IMenuItem {
  name: string;
  route?: ProfileStepsEnum;
  subMenu?: IMenuItem[];
  disabled?: boolean;
}

@Component({
  selector: 'app-profile-left-sidebar',
  templateUrl: './profile-left-sidebar.component.html',
  styleUrls: ['./profile-left-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileLeftSidebarComponent extends ChangableComponent implements OnInit {
  public me: User;
  public userProfile: UserProfile;
  public userAccountCheck: Partial<IAccountCheck>;
  public verification_status: UserProfileVerificationEnum;
  public hasSegment = AccountService.hasSegment;
  public hasAchievement = AccountService.hasAchievement;
  public visible = false;
  private _clickOutsideEnabled = false;
  private _timer: any;
  public profileMenuList: IMenuItem[];
  public profileMenuListTeacher: IMenuItem[] = [
    {
      name: 'profile-steps.personal-information',
      route: ProfileStepsEnum.personal,
      disabled: false,
      subMenu: [
        { name: 'profile-steps.acquaintance', route: ProfileStepsEnum.main },
        { name: 'profile-steps.about-you', route: ProfileStepsEnum.about },
        { name: 'profile-steps.contacts', route: ProfileStepsEnum.contacts },
        { name: 'profile-steps.education', route: ProfileStepsEnum.education },
        { name: 'profile-steps.work-experience', route: ProfileStepsEnum.experience },
      ],
    },
    { name: 'profile-steps.teaching', route: ProfileStepsEnum.teaching, disabled: false },
    {
      name: 'profile-steps.payment-info',
      route: ProfileStepsEnum.payment,
      disabled: false,
      subMenu: [
        {
          name: 'profile-steps.payin-info',
          route: ProfileStepsEnum.payment,
          disabled: !this.isAvailablePayout,
        },
        {
          name: 'profile-steps.payout-info',
          route: ProfileStepsEnum.payout,
          disabled: !this.isAvailablePayout,
        },
      ],
    },
    {
      name: 'profile-steps.services',
      route: ProfileStepsEnum.services,
      disabled: !this.features.isAvailable(FeatureEnum.profile_step_services),
    },
    {
      name: 'profile-steps.schedule',
      route: ProfileStepsEnum.schedule,
      disabled: !this.features.isAvailable(FeatureEnum.profile_step_calendar),
    },
    {
      name: 'profile-steps.application',
      route: ProfileStepsEnum.application,
      disabled: !this.features.isAvailable(FeatureEnum.profile_step_app),
    },
  ];

  public profileMenuListStudent: IMenuItem[] = [
    {
      name: 'profile-steps.personal-information',
      route: ProfileStepsEnum.personal,
      disabled: false,
      subMenu: [
        { name: 'profile-steps.acquaintance', route: ProfileStepsEnum.main },
        { name: 'profile-steps.about-you', route: ProfileStepsEnum.about, disabled: this.isLimitedVersion },
        { name: 'profile-steps.contacts', route: ProfileStepsEnum.contacts, disabled: this.isLimitedVersion },
      ],
    },
    {
      name: 'profile-steps.application',
      route: ProfileStepsEnum.application,
      disabled: !this.features.isAvailable(FeatureEnum.profile_step_app),
    },
  ];

  public activeTab: ProfileStepsEnum | string;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public router: Router,
    public route: ActivatedRoute,
    public screenService: ScreenService,
    public features: FeaturesService,
    public profileService: ProfileService,
    public accountService: AccountService,
    public contextService: ContextService,
    public sidebarService: SidebarService,
    public store: Store,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.store
      .select(getMe)
      .pipe(
        tap((me) => {
          this.me = me;
          this.profileMenuList = this.isTeacher ? this.teacherMenu : this.studentMenu;
        }),
        switchMap(() => this.store.select(getMyProfile).pipe(first())),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (profile) => {
          this.userProfile = profile;
          this.verification_status = profile?.verification_status;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    this.store
      .select(getAccountCheck)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (accountCheck) => {
          this.userAccountCheck = accountCheck;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (query) => {
        this.activeTab = query.get('step') ? query.get('step') : ProfileStepsEnum.main;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });

    this.sidebarService.leftState$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (visible) => {
        this.visible = visible;
        this.screenService.setBodyFixed(visible);
        this.clickOutsideEnabled = visible;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.visible = false;
        this.screenService.setBodyFixed(false);
        this.clickOutsideEnabled = false;
      },
    });
  }

  public get teacherMenu() {
    return this.profileMenuListTeacher.filter((item) => {
      if (item.subMenu) {
        item.subMenu = item.subMenu.filter((subitem) => !subitem.disabled);
      }

      if (!item.disabled) {
        return item;
      }
    });
  }

  public get studentMenu() {
    return this.profileMenuListStudent.filter((item) => {
      if (item.subMenu) {
        item.subMenu = item.subMenu.filter((subitem) => !subitem.disabled);
      }

      if (!item.disabled) {
        return item;
      }
    });
  }

  public get isAvailablePayout() {
    return this.features.isAvailable(FeatureEnum.finance_payouts);
  }

  public get clickOutsideEnabled(): boolean {
    return this._clickOutsideEnabled;
  }

  public set clickOutsideEnabled(value: boolean) {
    this.onBrowserOnly(() => {
      if (value) {
        this.clearTimeout(this._timer);
        this._timer = this.setTimeout(() => {
          this._clickOutsideEnabled = true;
          this.detectChanges();
        }, 200);
      } else {
        this._clickOutsideEnabled = false;
        this.detectChanges();
      }
    });
  }

  public closeSidebar() {
    this.sidebarService.setLeftSidebarState(false);
  }

  public navigateTo(url: string): void {
    if (url === String(ProfileStepsEnum.personal)) {
      url = ProfileStepsEnum.main;
    } else if (url === String(ProfileStepsEnum.payinfo)) {
      url = ProfileStepsEnum.payment;
    }
    this.router.navigate(['/profile/' + url]);
    this.closeSidebar();
  }

  public isActive(step: IMenuItem): boolean {
    const ifSubMenu = step?.subMenu?.find((el) => String(el.route) === this.activeTab);
    return this.activeTab === String(step.route) || !!ifSubMenu;
  }

  public isChecked(step: IMenuItem) {
    switch (step.route) {
      case ProfileStepsEnum.personal:
        return this.isTeacher
          ? AccountService.isAllStepsFilledProfileTeacher(this.userAccountCheck)
          : AccountService.isAllStepsFilledProfileStudent(this.userAccountCheck);
      case ProfileStepsEnum.teaching:
        return !this.userAccountCheck?.no_teaching_subjects;
      case ProfileStepsEnum.services:
        return this.hasAchievement(this.me, UserAchievementEnum.created_user_services_once);
      case ProfileStepsEnum.schedule:
        return !this.userAccountCheck?.no_working_hours;
      case ProfileStepsEnum.payment:
        return !this.userAccountCheck?.no_payins && !this.userAccountCheck?.no_payouts;
      case ProfileStepsEnum.payinfo:
        return !this.userAccountCheck?.no_payins;
      case ProfileStepsEnum.payout:
        return !this.userAccountCheck?.no_payouts;
      case ProfileStepsEnum.application:
        return this.hasSegment(this.me, UserSegmentEnum.use_mobile_app);
      case ProfileStepsEnum.main:
        return !(
          this.userAccountCheck?.no_first_name ||
          this.userAccountCheck?.no_last_name ||
          this.userAccountCheck?.no_country_id ||
          this.userAccountCheck?.no_userpic_id
        );
      case ProfileStepsEnum.about:
        return !(
          this.userAccountCheck?.no_spoken_languages ||
          this.userAccountCheck?.no_birth_date ||
          this.userAccountCheck?.no_about
        );
      case ProfileStepsEnum.contacts:
        return !(this.userAccountCheck?.no_confirmed_email || this.userAccountCheck?.no_confirmed_mobile_phone);
      case ProfileStepsEnum.education:
        return !this.userAccountCheck?.no_education_schools;
      case ProfileStepsEnum.experience:
        return !this.userAccountCheck?.no_work_experience;
    }
  }

  public isClickable(step: IMenuItem) {
    if (this.isLimitedVersion) {
      return true;
    }
    if (this.isTeacher) {
      switch (step.route) {
        case ProfileStepsEnum.services:
        case ProfileStepsEnum.schedule:
        case ProfileStepsEnum.payinfo:
        case ProfileStepsEnum.card:
        case ProfileStepsEnum.application:
          return (
            this.hasSegment(this.me, UserSegmentEnum.has_verified_teacher_profile) ||
            this.verification_status === UserProfileVerificationEnum.sent ||
            this.verification_status === UserProfileVerificationEnum.resent
          );
        case ProfileStepsEnum.teaching:
          return AccountService.isAllStepsFilledProfileTeacher(this.userAccountCheck);
        default:
          return true;
      }
    } else {
      return true;
    }
  }

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this.me);
  }

  public get isLimitedVersion(): boolean {
    return FeaturesService.isLimitedVersion;
  }

  public isLimited(subItem: IMenuItem) {
    return this.isLimitedVersion && subItem.name === 'profile-steps.acquaintance' && !this.isTeacher;
  }

  public trackByFn(index) {
    return index;
  }
}
