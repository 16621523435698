import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AccountService, ContextService, CrispChatService, PlatformService, ProfileService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getMe, getMyProfile } from '@store/reducers/profile.reducer';
import { User, UserProfile } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';
import {loadMe} from "@store/actions/profile.actions";
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-profile-right-sidebar',
  templateUrl: './profile-right-sidebar.component.html',
  styleUrls: ['./profile-right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileRightSidebarComponent extends ChangableComponent implements OnInit {
  public me: User;
  public profile: Partial<UserProfile>;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public crispChat: CrispChatService,
    public contextService: ContextService,
    public profileService: ProfileService,
    public store: Store,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this.me = me;
          this.loadProfile();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        }
      });
  }

  protected loadProfile() {
    if (this.me.profile) {
      this.profile = this.me.profile;
      this.contextService.patchProfile(this.profile);
    } else {
      this.store
        .select(getMyProfile)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (profile) => {
            this.profile = profile;
            this.contextService.patchProfile(this.profile);
            this.markForCheck();
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
          },
        });
    }
  }

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this.me);
  }

  public openCrispChat() {
    this.crispChat.openChat();
  }
}
