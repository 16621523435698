import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessTokenService } from '@app/core/services/access-token.service';
import { ChatService } from '@app/core/services/chat.service';
import { HeaderComponent } from '@core/header/header.component';
import { ContextService, CrispChatService, FeaturesService, LanguageService, ScreenService } from '@core/services';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import * as ProfileAction from '@store/actions/profile.actions';
import { FeatureEnum, Language, User, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss'],
})
export class ProfileLayoutComponent extends DestroyableComponent implements OnInit, AfterViewInit {
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.receiveMessage(event);
  }

  public pageStyle = '';
  public isChatVisible = false;
  public isNotificationsVisible = false;
  public alternativeChatState = false;
  public unreadMessageCount: number;
  public isFooterVisible = true;
  public fileFromChat = null;

  public safeChatUrl: SafeResourceUrl;
  public currentLanguage: Language;
  public iframe: any;

  @ViewChild('app_header', { read: ViewContainerRef }) private headerContainerRef: ViewContainerRef;
  private headerComponentRef: ComponentRef<HeaderComponent>;
  protected me: User;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public contextService: ContextService,
    public features: FeaturesService,
    private screenService: ScreenService,
    public crispChat: CrispChatService,
    public chatService: ChatService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public store: Store,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private accessTokenService: AccessTokenService,
    private languageService: LanguageService,
    public deviceService: DeviceDetectorService,
  ) {
    super();
  }

  ngOnInit() {
    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.isFooterVisible = width < 870;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.isFooterVisible = false;
      },
    });

    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        const data = this.route.snapshot.data;
        this.pageStyle = data.page_style || '';
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });

    if (this.deviceService.isDesktop()) {
      this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (me) => {
          this.me = me;
          this.generateChatLink();
          this.isChatVisible = me && me.status !== UserStatusEnum.guest;
          this.isNotificationsVisible = me && me.status !== UserStatusEnum.guest;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
    }
    this.store.dispatch(ProfileAction.loadMe());
  }

  public ngAfterViewInit() {
    void this.loadEmptyComponent().catch();
  }

  // TODO ленивая загрузка компонента
  private async loadEmptyComponent(): Promise<void> {
    if (!this.headerContainerRef) {
      return;
    }
    const { HeaderComponent } = await import('../../../core/header/header.component');
    this.headerContainerRef?.clear();
    this.headerComponentRef = this.headerContainerRef?.createComponent(HeaderComponent);
    this.headerComponentRef.changeDetectorRef.detectChanges();
  }

  public get isAvailableChat(): boolean {
    return this.features.isAvailable(FeatureEnum.chat) && this.isBrowser;
  }

  public onChatWindowStatusChanged(status: boolean) {
    this.alternativeChatState = status;
    this.screenService.setBodyFixed(status);
  }

  public openChatWindow() {
    this.chatService.openChat();
  }

  public openProfile(e) {
    this.router.navigateByUrl('/u/' + e.slug).then(() => true);
    if (!this.deviceService.isDesktop()) {
      this.setTimeout(() => {
        this.onChatWindowStatusChanged(false);
        this.chatService.closeChat();
      }, 200);
    }
  }

  public onMouseenter() {
    this.screenService.setBodyFixed(true);
  }

  public onMouseleave() {
    this.screenService.setBodyFixed(false);
  }

  public set unreadCount(count) {
    this.unreadMessageCount = count;
    this.cdr.detectChanges();
  }

  public get unreadCount() {
    return this.unreadMessageCount;
  }

  public openFileFromChat(event) {
    this.chatService.openChat();
    this.fileFromChat = event;
  }

  public closeFileFromChat() {
    this.chatService.closeChat();
    this.fileFromChat = null;
  }

  public generateChatLink() {
    if (!this.me?.id.length) {
      return;
    }
    let url = '';
    const hostUrl = environment.chat_app_host;
    const frameUrl = `${hostUrl}/`;
    const token = this.accessTokenService.getAccessToken();
    const host = environment.gamelib_app_api_host.includes('local') ? 'onclass.tech' : environment.gamelib_app_api_host;
    const lang = this.currentLanguage?.code || 'en';
    const myId = this.me.id;
    url = `${frameUrl}?token=${token}&userId=${myId}&host=${host}&lang=${lang}`;

    this.safeChatUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public receiveMessage(event: MessageEvent) {
    const message = event?.data;
    if (typeof message === 'string' || message instanceof String) {
      if (message.includes('chatapp')) {
        const messageArray = message.split('::');
        const action = messageArray[1];
        const value = messageArray[2];
        if (action && action === 'windowstatus') {
          this.onChatWindowStatusChanged(value === 'true');
        } else if (action && action === 'togglewindow') {
          // Нажали кнопку "закрыть/свернуть чат"
        } else if (action && action === 'unreadcount') {
          // Кол-во непрочитанных сообщений
          this.iframe = document.getElementById('chatFrame');
          this.chatService.setChatFrame(this.iframe);
          this.unreadCount = Number(value);
        } else if (action && action === 'openprofile') {
          // Перейти в профиль
          if (!!value.length) {
            this.openProfile(value);
          }
        } else if (action && action === 'openfile') {
          // Открыть файл из чата
          if (!!value.length) {
            this.openFileFromChat(value);
          }
        } else if (action && action === 'opensupport') {
          // Чат суппорта
          this.crispChat.openChat();
        }
      }
    }
  }
}
