import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// внешние модули
import { LanguageSelectorModule } from '../../components/language-selector/language-selector.module';

// компоненты
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LandingLayoutComponent } from './landing-layout.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, LandingLayoutComponent],
  imports: [CommonModule, RouterModule, TranslateModule, LanguageSelectorModule],
  exports: [LandingLayoutComponent],
})
export class LandingLayoutModule {}
