<footer class="footer" [ngClass]="pageStyle" itemscope itemtype="http://schema.org/SiteNavigationElement">
  <div class="column-wrap">
    <div class="column column1">
      <div *ngIf="!isLimitedVersion" class="links">
        <a routerLink="/lessons" class="link normal" itemprop="url">{{ 'menu.landing-footer.library' | translate }}</a>
        <a [routerLink]="'/' + routes.classes" class="link normal" itemprop="url">{{ 'menu.landing-footer.classes' | translate }}</a>
        <a [routerLink]="'/' + routes.tutors " class="link normal" itemprop="url">{{ 'menu.landing-footer.teachers' | translate }}</a>
      </div>
    </div>
    <div class="column column2">
      <div class="links">
        <a routerLink="/privacy" class="link normal" itemprop="url">{{ 'menu.landing-footer.privacy' | translate }}</a>
        <a routerLink="/salesrefund" class="link normal" itemprop="url">{{ 'menu.landing-footer.sales' | translate }}</a>
        <a routerLink="/about" class="link normal" itemprop="url">{{ 'menu.landing-footer.about' | translate }}</a>
      </div>
      <div class="links">
        <a routerLink="/terms" class="link normal" itemprop="url">{{ 'menu.landing-footer.terms' | translate }}</a>
        <a routerLink="/offer" class="link normal" itemprop="url">{{ 'menu.landing-footer.public' | translate }}</a>
        <span class="link normal">{{ 'APP_NAME' | translate }} | {{ copyYear }}</span>
      </div>
    </div>
  </div>
</footer>
