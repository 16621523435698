import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { NgEventBus } from 'ng-event-bus';
import { OnUiButtonModule } from 'onclass-ui';
import { SimplePieChartComponent } from './components/simple-pie-chart/simple-pie-chart.component';
import { CleanLayoutModule } from './layouts/clean/clean-layout.module';
import { ConstructorLayoutModule } from './layouts/constructor-layout/constructor-layout.module';
import { LandingLayoutModule } from './layouts/landing/landing-layout.module';
import { MainLayoutModule } from './layouts/main/main-layout.module';
import { ProfileLayoutModule } from './layouts/profile/profile-layout.module';
@NgModule({
  declarations: [SimplePieChartComponent],
  providers: [NgEventBus],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CleanLayoutModule,
    ConstructorLayoutModule,
    LandingLayoutModule,
    MainLayoutModule,
    ProfileLayoutModule,
    IconComponent,
    OnUiButtonModule,
  ],
  exports: [SimplePieChartComponent],
})
export class CoreModule {}
