<div class="header" [ngClass]="pageStyle">
  <div class="header-item header-item-menu" (click)="menuVisible = !menuVisible">
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="menu-icon" [class.expand]="menuVisible">
      <rect width="20" height="3.33333" rx="1.66667"/>
      <rect y="8.33334" width="20" height="3.33333" rx="1.66667"/>
      <rect y="16.6667" width="20" height="3.33333" rx="1.66667"/>
    </svg>
    <div class="menu-items" [ngClass]="{ visible: menuVisible }">
      <div class="menu-links">
        <a [routerLink]="'/' + routes.lessons" class="menu-item normal">{{ 'menu.landing-top.library' | translate }}</a>
        <a [routerLink]="'/' + routes.classes"
          class="menu-item normal">{{ 'menu.landing-top.teachers' | translate }}</a>
        <a [routerLink]="'/' + routes.classes" class="menu-item normal">{{ 'menu.landing-top.classes' | translate }}</a>
      </div>
      <div class="btn-signin" (click)="authorize()">{{ 'menu.landing-top.signin' | translate }}</div>
      <div></div>
    </div>
  </div>
  <div class="header-item header-item-nav">
    <div class="nav-items">
      <a [routerLink]="'/' + routes.lessons" class="nav-item">{{ 'menu.landing-top.library' | translate }}</a>
      <a [routerLink]="'/' + routes.tutors" class="nav-item">{{ 'menu.landing-top.teachers' | translate }}</a>
      <a [routerLink]="'/' + routes.classes" class="nav-item">{{ 'menu.landing-top.classes' | translate }}</a>
    </div>
    <div class="nav">
    </div>
  </div>
  <div class="header-item header-item-logo">
    <a routerLink="/" class="logo"></a>
  </div>
  <div class="header-item header-item-signin">
    <div class="btn-signin" (click)="authorize()">{{ 'menu.landing-top.signin' | translate }}</div>
  </div>
  <app-language-selector></app-language-selector>
</div>
