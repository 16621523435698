<ng-container *ngTemplateOutlet="statusWidget"></ng-container>
<ng-container *ngTemplateOutlet="chatWidget"></ng-container>

<ng-template #chatWidget>
  <section class="profile-right-sidebar">
    <p class="title">{{ 'help-widget.title' | translate }}</p>
    <p class="description">{{ 'help-widget.description' | translate }}</p>
    <p class="action" (click)="openCrispChat()">
      <app-icon
        class="dialog-icon"
        [width]="22"
        [height]="22"
        [icon]="'dialog-icon'">
      </app-icon>
      <span>{{ 'help-widget.action' | translate }}</span>
    </p>
  </section>
</ng-template>

<ng-template #statusWidget>
  <app-verification-widget *ngIf="isTeacher" [user]="me" view="mini"></app-verification-widget>
</ng-template>