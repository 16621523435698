import { Component, Input } from '@angular/core';
import { AuthService, ContextService } from '@core/services';
import { EContentPanelRoute } from 'lingo2-models';

@Component({
  selector: 'app-old-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() pageStyle: string;
  public me$ = this.contextService.me$;
  public menuVisible = false;
  public routes = EContentPanelRoute;

  constructor(private authService: AuthService, private contextService: ContextService) {}

  public authorize() {
    this.authService.showAuthModal();
  }
}
